import queryString from 'query-string';
import { toSnakeCase } from './pascalCasing';
const parsed = queryString.parse(
  typeof window !== 'undefined' ? window.location.search : ''
);

const setStorage = () => {
  if (parsed && Object.keys(parsed).length !== 0) {
    const filteredObj = Object.entries(parsed).reduce(
      (a, [k, v]) => (v ? ((a[k] = v), a) : a),
      {}
    );
    sessionStorage.setItem('jtkNetFd', JSON.stringify(filteredObj));
    sessionStorage.setItem(
      'formData',
      JSON.stringify(toSnakeCase(filteredObj))
    );
  }
  return;
};

const filteredParams = () => {
  if (parsed) {
    const { loan_purpose, credit_rating, zipcode, ...newFilteredData } = parsed;
    const encoded = new URLSearchParams(newFilteredData).toString();
    return encoded;
  }
  return '';
};

const getRedirectURL = ({ credit_rating, loan_purpose, zipcode }) => {
  if (credit_rating && loan_purpose) {
    let lp = '';
    let cr = '';
    const lpData = loan_purpose.toLowerCase();
    const crData = credit_rating.toLowerCase();

    switch (crData) {
      case 'excellent':
        cr = 'GC';
        break;
      case 'good':
        cr = 'GC';
        break;
      case 'average':
        cr = 'GC';
        break;
      case 'fair':
        cr = 'BC';
        break;
      case 'poor':
        cr = 'BC';
        break;
      default:
        cr = 'GC';
        break;
    }
    switch (lpData) {
      case 'refinance':
        lp = 'R';
        break;
      case 'purchase':
        lp = 'P';
        break;
      default:
        lp = 'P';
        break;
    }

    const thankyouPage = `/thankyou${
      cr + lp
    }.html?loan_purpose=${loan_purpose}&credit_rating=${credit_rating}&zipcode=${zipcode}&${filteredParams()}`;
    return thankyouPage;
  }
  return `/thankyouGCR?loan_purpose=Refinance&credit_rating=good`; // default
};

export { setStorage, filteredParams, getRedirectURL };
