// This is necessary because some of the fields (as far as the src folder knows) are pascalCased or snake_cased.
const keepCase = [
  'req_id',
  'ElectricBill',
  'ElectricCompany',
  'HasSolar',
  'Shade',
  'Windows',
  'WindowType',
  'ProjectTimeFrame',
  'HasSolarInterest',
  'RoofingMaterial',
  'RoofingMaterial',
  'CurrentlyInsured',
  'Gender',
  'EmploymentStatus',
  'IsTobaccoUser',
  'MaritalStatus',
  'HasConditions',
  'AnnualHouseholdIncome',
  'CoverageType',
  'BirthYear',
  'BirthMonth',
  'BirthDay',
  'Weight',
  'Feet',
  'Inches',
  'NumberOfBathrooms',
  'NumberOfBedrooms',
  'NumberOfStories',
  'InsuranceCarrier',
  'RoofingMaterial',
  'YearHomeBuilt',
  'SquareFootage',
  'HasInsuranceClaims',
  'HasAutoInsuranceInterest',
  'PropertyType',

  'MedicareEnrolled',
  'NumberOfPeopleInHouse',
  'NumberOfChildrenInHouse',
  'LookingToExpandFamily',
  'HasLifeEvent',
  'HasRecievedSsd',
  'BirthDateCombined',
  'BirthDate',
  //
  'OwnOrRent',
  'VehicleMake',
  'VehicleMake2',
  'VehicleModel',
  'VehicleModel2',
  'VehicleYear',
  'VehicleYear2',
  'DesiredCoverage',
  'Accident',
  'Violation',
  'RequiresSSR2',
  'LicenseStatus',
  'DesiredHomeInsurance',
  'VehiclePrimaryUse',
  'VehicleParkedAt',
  'CurrentlyInsured',
  'CurrentCarrier',
  'Education',
  'Occupation',
  'AnnualMileage',
  'NumberOfVehicles',
  'DoesRequireSR22',
  'TrafficIncidents',
  'IsPrimaryResidence',
  'DesireAutoWarranty',
];

export { keepCase };
