import React, { useEffect } from 'react';
import { Container } from 'reactstrap';
import { setStorage } from '../utils/utilFunctions';
import '../scss/index.scss';
export default function Layout({ isfluid, children, hideHeader = false }) {
  useEffect(() => {
    // sets GTM data layer to the window for various use
    if (typeof window !== 'undefined') {
      window.scData = [
        {
          siteVertical: 'other',
          siteName: 'mortgage.connectedrates.com',
          defaultOffer: '',
          siteCompany: 'Parent',
          siteBuyer: 'Combined',
        },
      ];
    }

    setStorage();
  }, []);

  return (
    <Container className={hideHeader ? 'noHeader' : ''} fluid={isfluid}>
      {children}
    </Container>
  );
}
