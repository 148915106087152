import React from 'react';
import { Link } from 'gatsby';
import logo from '../images/logo.png';

const Header = () => {
  return (
    <div className="header">
      <Link to="/">
        <img src={logo} className="logo" alt="Mortgage Connected Rates Logo" />
      </Link>
    </div>
  );
};

export default Header;
