import { keepCase } from './keepQueryParams';

const rulesEnginePascalHash = {
  additional_cash_needed: 'AdditionalCashNeeded',
  AdvertiserName: 'AdvertiserName',
  affiliate_id: 'AffiliateId',
  // AffiliateId: 'AffiliateId',
  BatchId: 'BatchId',
  BridgePageName: 'BridgePageName',
  CallData: 'CallData',
  campaignId: 'CampaignId',
  CampaignName: 'CampaignName',
  CheckPreviousNumberOfDate: 'CheckPreviousNumberOfDate',
  ChildOfferId: 'ChildOfferId',
  CkmAffiliateId: 'CkmAffiliateId',
  CkmRequestId: 'CkmRequestId',
  Client: 'Client',
  code: 'Code',
  CompanyName: 'CompanyName',
  credit_rating: 'CreditRating',
  CreditRating: 'CreditRating',
  Destination: 'Destination',
  DestinationNumber: 'DestinationNumber',
  DistributionType: 'DistributionType',
  email: 'Email',
  Email: 'Email',
  employer: 'Employer',
  Error: 'Error',
  est_property_value: 'EstPropertyValue',
  est_purchase_price: 'EstPurchasePrice',
  estimated_down_payment: 'EstimatedDownPayment',
  exclusiveLead: 'ExclusiveLead',
  ExternalSystem: 'ExternalSystem',
  ExternalVendorId: 'ExternalVendorId',
  FePayload: 'FePayload',
  Filters: 'Filters',
  FingerprintHash: 'FingerprintHash',
  InfutorUuid: 'InfutorUuid',
  loan_purpose: 'LoanPurpose',
  LoanPurpose: 'LoanPurpose',
  MapName: 'MapName',
  MaxNumberRows: 'MaxNumberRows',
  Message: 'Message',
  MessageType: 'MessageType',
  MessageVertical: 'MessageVertical',
  MinSignupId: 'MinSignupId',
  mortgage_amount: 'MortgageAmount',
  mortgage_rate: 'MortgageRate',
  moving_date: 'MovingTimeFrame',
  MySqlSignupId: 'MySqlSignupId',
  offer_id: 'OfferId',
  OfferId: 'OfferId',
  opt_served_military: 'MilitaryService',
  Override: 'Override',
  personal_loan: 'PersonalLoan',
  phone: 'Phone',
  PhoneNumber: 'PhoneNumber',
  Prefix: 'Prefix',
  property_type: 'PropertyType',
  Provider: 'Provider',
  ProviderRvmId: 'ProviderRvmId',
  RequestId: 'RequestId',
  scbc: 'SCBC',
  SendStatus: 'SendStatus',
  SignupId: 'SignupId',
  SignupPayload: 'SignupPayload',
  SIH: 'SIH',
  SinceDate: 'SinceDate',
  site_name: 'SiteName',
  SiteName: 'SiteName',
  SiteVertical: 'SiteVertical',
  source: 'Source',
  Source: 'Source',
  state: 'State',
  Status: 'Status',
  target: 'Target',
  TestResults: 'TestResults',
  TransactionDate: 'TransactionDate',
  triggered_send_key: 'TriggeredSendKey',
  TrustedFormCertUrl: 'TrustedFormCertUrl',
  UniversalLeadId: 'UniversalLeadId',
  url: 'URL',
  UserIp: 'UserIp',
  uuid: 'UUID',
  Vertical: 'Vertical',
  zipcode: 'ZipCode',
  ZipCode: 'ZipCode',
  LoanToValue: 'LoanToValue',
  InsurancePurpose: 'InsurancePurpose',
  home_alarm_installed: 'HasAlarmInstalled',
  home_alarm_professional_install: 'ProfessionalInstall',
  home_alarm_purchase_timeframe: 'HomeAlarmPurchaseTimeframe',
  home_alarm_service_type: 'ServiceType',
};

const pascalHash = {
  CreditRepairRequested: 'CreditRepairRequested',
  LoanToValue: 'LoanToValue',
  ___pageid___: 'PageId',
  Accident: 'Accident',
  acres: 'Acres',
  additional_cash_needed: 'CashNeededAmount',
  additional_info: 'AdditionalInfo',
  address: 'Address',
  adoption_path: 'AdoptionPath',
  adoption_type: 'AdoptionType',
  affiliate_id: 'CkmAffiliateId',
  // affiliate_id: 'AffiliateId',
  age: 'Age',
  AgeFirstLicensed: 'AgeFirstLicensed',
  agent_found: 'AgentFound',
  AnnualHouseholdIncome: 'AnnualHouseholdIncome',
  AnnualIncome: 'AnnualIncome',
  AnnualMileage: 'AnnualMileage',
  auto_insurance: 'AutoInsurance',
  BirthDate: 'BirthDate',
  bk_foreclosure_repo: 'BankruptcyForeclosure',
  BuyerGroupContractId: 'BuyerGroupCkmBuyerContractId',
  BuyerGroupDailyCap: 'BuyerGroupDailyCap',
  BuyerGroupFilter: 'BuyerGroupFilter',
  BuyerGroupId: 'BuyerGroupId',
  BuyerGroupName: 'BuyerGroupName',
  BuyerId: 'BuyerId',
  BuyerName: 'BuyerName',
  BuyingSituation: 'BuyingSituation',
  campaign_id: 'CkmCampaignId',
  // campaign_id: 'CampaignId',
  cash_needed: 'CashNeeded',
  city: 'City',
  ckm_subid_1: 'CkmSubId1',
  ckm_subid_2: 'CkmSubId2',
  ckm_subid_3: 'CkmSubId3',
  ckm_subid_4: 'CkmSubId4',
  ckm_subid_5: 'CkmSubId5',
  ContactedByAgent: 'ContactedByAgent',
  CoverageType: 'CoverageType',
  credit_rating: 'CreditRating',
  current_lender: 'CurrentLender',
  current_loan_type: 'CurrentLoanType',
  CurrentLender: 'CurrentLender',
  CurrentLenderOriginal: 'CurrentLenderOriginal',
  CurrentLoanType: 'CurrentLoanType',
  CurrentlyInsured: 'CurrentlyInsured',
  DesiredAmount: 'DesiredAmount',
  DesiredCoverage: 'DesiredCoverage',
  DesiredHvac: 'DesiredHvac',
  DesireHomeownersInsurance: 'DesireHomeownersInsurance',
  disclosure_name: 'DisclosureName',
  DoesDangerActivities: 'DoesDangerActivities',
  DoesRequireSR22: 'DoesRequireSR22',
  DownPayment: 'DownPayment',
  DriverVehicleUsageId: 'DriverVehicleUsageId',
  due_date: 'DueDate',
  Education: 'Education',
  ElectricBill: 'ElectricBill',
  ElectricCompany: 'ElectricCompany',
  // email: 'EmailOriginal',
  email: 'Email',
  employer: 'EmploymentStatus',
  EquipmentFinance: 'EquipmentFinance',
  est_property_value: 'EstimatedPropertyValue',
  est_purchase_price: 'EstimatedPurchasePrice',
  estimated_debt: 'EstimatedDebt',
  estimated_down_payment: 'EstimatedDownPayment',
  ExpirationDate: 'ExpirationDate',
  ExternalVendorData: 'ExternalVendorData',
  ExternalVendorId: 'ExternalVendorId',
  first_name: 'FirstName',
  Gender: 'Gender',
  HasAgent: 'HasAgent',
  HasAlarmInstalled: 'HasAlarmInstalled',
  HasAutoInsuranceInterest: 'HasAutoInsuranceInterest',
  HasBankruptcy: 'HasBankruptcy',
  HasConditions: 'HasConditions',
  HasCostco: 'HasCostco',
  HasFhaLoan: 'HasFhaLoan',
  HasForeclosure: 'HasForeclosure',
  HasHighBMI: 'HasHighBMI',
  HasHome: 'HasHome',
  HasLifeEvent: 'HasLifeEvent',
  HasPMI: 'HasPMI',
  HasProofOfInsurance: 'HasProofOfInsurance',
  HasSolar: 'HasSolar',
  home_found: 'FoundHome',
  home_insurance: 'HomeInsurance',
  homeowner: 'HomeOwner',
  HomeUse: 'HomeUse',
  ileads: 'Payload',
  InsuranceCarrier: 'InsuranceCarrier',
  InsurancePurpose: 'InsurancePurpose',
  IsFirstTimeBuyer: 'IsFirstTimeBuyer',
  IsHomeOwner: 'IsHomeOwner',
  IsSolarZip: 'IsSolarZip',
  IsTobaccoUser: 'IsTobaccoUser',
  last_name: 'LastName',
  LicenseStatus: 'LicenseStatus',
  loan_purpose: 'LoanPurpose',
  LoanPurpose: 'LoanPurpose',
  LookingToExpandFamily: 'LookingToExpandFamily',
  MapName: 'MapName',
  MaritalStatus: 'MaritalStatus',
  married_single: 'MarriedSingle',
  MedicareEnrolled: 'MedicareEnrolled',
  MoneyUse: 'MoneyUse',
  MonthlyPayment: 'MonthlyPayment',
  mortgage_amount: 'MortgageAmount',
  mortgage_insurance: 'MortgageInsurance',
  mortgage_rate: 'CurrentMortgageRate',
  MortgageAmount: 'MortgageAmount',
  MortgageRate: 'MortgageRate',
  moving_date: 'MovingTimeFrame',
  MovingTimeFrame: 'MovingTimeFrame',
  NumberOfAcres: 'NumberOfAcres',
  NumberOfChildrenInHouse: 'NumberOfChildrenInHouse',
  NumberOfPeopleInHouse: 'NumberOfPeopleInHouse',
  NumberOfVehicles: 'NumberOfVehicles',
  Occupation: 'Occupation',
  offer_id: 'CkmOfferId',
  // offer_id: 'OfferId',
  open_adoption: 'OpenAdoption',
  opt_mortgage_late_months: 'LateOnMortgageMonths',
  opt_served_military: 'MilitaryService',
  own_home: 'OwnOrRent',
  own_rent: 'OwnOrRent',
  OwnedLeasedOrFinanced: 'OwnedLeasedOrFinanced',
  OwnOrRent: 'OwnOrRent',
  ParentUuid: 'ParentUuid',
  PayFrequency: 'PayFrequency',
  personal_loan: 'PersonalLoan',
  phone_mobile: 'PhoneSecondary',
  phone_primary: 'PhoneNumber',
  PhoneSecondary: 'PhoneSecondary',
  PolicyStartDate: 'PolicyStartDate',
  ProfessionalInstall: 'ProfessionalInstall',
  Project: 'Project',
  project_timeframe: 'ProjectTimeFrame',
  project_type: 'ProjectType',
  ProjectTimeFrame: 'ProjectTimeFrame',
  property_city: 'NewPropertyCity',
  property_state: 'NewPropertyState',
  property_type: 'PropertyType',
  property_zip: 'NewPropertyZipcode',
  PropertyCity: 'PropertyCity',
  PropertyState: 'PropertyState',
  PropertyType: 'PropertyType',
  PropertyValue: 'PropertyValue',
  PropertyZip: 'PropertyZip',
  PurchasePrice: 'PurchasePrice',
  PurchaseYear: 'PurchaseYear',
  RateType: 'RateType',
  refi_purpose: 'RefinancePurpose',
  RefinancePurpose: 'RefinancePurpose',
  religious_affiliation: 'ReligiousAffiliation',
  request_id: 'CkmRequestId',
  // request_id: 'RequestId',
  residential: 'Residential',
  ReverseMortgage: 'ReverseMortgage',
  RoofingMaterial: 'RoofingMaterial',
  Scenario: 'SplitScenarioFilter',
  sell_house: 'SellHouse',
  SellingReason: 'SellingReason',
  server: 'SiteServer',
  ServiceType: 'ServiceType',
  Shade: 'Shade',
  signup_id: 'MysqlSignupId',
  site_name: 'SiteName',
  site_url: 'SignupUrl',
  SiteVertical: 'SiteVertical',
  SplitScenarioId: 'SplitScenarioId',
  SplitScenarioName: 'SplitScenarioName',
  SplitScenarioOrdinal: 'SplitScenarioOrdinal',
  state: 'State',
  SubVerticalName: 'SubVerticalName',
  talon: 'FingerprintHash',
  tcpa: 'TcpaType',
  TcpaId: 'TcpaId',
  TcpaType: 'TcpaType',
  TestVariation: 'TestVariation',
  throttle_id: 'ThrottleDecisionId',
  tier: 'Tier',
  TierLevel: 'SCTier',
  time_on_form: 'TimeOnForm',
  total_loan_amount: 'TotalLoanAmount',
  TyPageShown: 'TyPageShown',
  type_of_therapy: 'TypeOfTherapy',
  universal_leadid: 'UniversalLeadId',
  user_agent: 'UserAgent',
  user_ip: 'IPAddress',
  uuid: 'UUID',
  ValueIncrease: 'ValueIncrease',
  VehicleMake: 'VehicleMake',
  VehicleMake2: 'VehicleMake2',
  VehicleModel: 'VehicleModel',
  VehicleModel2: 'VehicleModel2',
  VehicleParkedAt: 'VehicleParkedAt',
  VehiclePrimaryUse: 'VehiclePrimaryUse',
  VehicleYear: 'VehicleYear',
  VehicleYear2: 'VehicleYear2',
  vertical: 'Vertical',
  Vin: 'Vin',
  Violation: 'Violation',
  windows: 'WindowCount',
  WindowType: 'WindowType',
  xxTrustedFormCertUrl: 'TrustedFormCertUrl',
  YearHomeBuilt: 'YearHomeBuilt',
  zip4: 'ZipCode4',
  zipcode: 'ZipCode',
  Address: 'Address',
  Email: 'Email',
  facebook_id: 'FacebookId',
  fname: 'FirstName',
  lname: 'LastName',
  phone: 'PhoneNumber',
  street_address: 'Address',
  zip: 'ZipCode',
  Acres: 'Acres',
  AdditionalInfo: 'AdditionalInfo',
  AdoptionPath: 'AdoptionPath',
  AdoptionType: 'AdoptionType',
  // AffiliateId: 'CkmAffiliateId',
  Age: 'Age',
  AgentFound: 'AgentFound',
  AutoInsurance: 'AutoInsurance',
  BankruptcyForeclosure: 'BankruptcyForeclosure',
  BuyerGroupCkmBuyerContractId: 'BuyerGroupCkmBuyerContractId',
  CashNeeded: 'CashNeeded',
  CashNeededAmount: 'CashNeededAmount',
  City: 'City',
  CkmAffiliateId: 'CkmAffiliateId',
  CkmCampaignId: 'CkmCampaignId',
  CkmOfferId: 'CkmOfferId',
  CkmRequestId: 'CkmRequestId',
  CkmSubId1: 'CkmSubId1',
  CkmSubId2: 'CkmSubId2',
  CkmSubId3: 'CkmSubId3',
  CkmSubId4: 'CkmSubId4',
  CkmSubId5: 'CkmSubId5',
  CreditRating: 'CreditRating',
  CurrentLoanAmount: 'CurrentLoanAmount',
  CurrentMortgageRate: 'CurrentMortgageRate',
  DisclosureName: 'DisclosureName',
  DueDate: 'DueDate',
  EmailOriginal: 'EmailOriginal',
  EmploymentStatus: 'EmploymentStatus',
  EstimatedDebt: 'EstimatedDebt',
  EstimatedDownPayment: 'EstimatedDownPayment',
  EstimatedPropertyValue: 'EstimatedPropertyValue',
  EstimatedPurchasePrice: 'EstimatedPurchasePrice',
  FacebookId: 'FacebookId',
  FingerprintHash: 'FingerprintHash',
  FirstName: 'FirstName',
  FoundHome: 'FoundHome',
  HomeInsurance: 'HomeInsurance',
  HomeOwner: 'HomeOwner',
  IPAddress: 'IPAddress',
  LastName: 'LastName',
  LateOnMortgageMonths: 'LateOnMortgageMonths',
  MarriedSingle: 'MarriedSingle',
  MilitaryService: 'MilitaryService',
  MortgageInsurance: 'MortgageInsurance',
  MysqlSignupId: 'MysqlSignupId',
  NewPropertyCity: 'NewPropertyCity',
  NewPropertyState: 'NewPropertyState',
  NewPropertyZipcode: 'NewPropertyZipcode',
  OpenAdoption: 'OpenAdoption',
  OptInDate: 'OptInDate',
  OwnRent: 'OwnOrRent',
  PageId: 'PageId',
  Payload: 'Payload',
  PhoneNumber: 'PhoneNumber',
  ProjectType: 'ProjectType',
  ReligiousAffiliation: 'ReligiousAffiliation',
  Residential: 'Residential',
  SCTier: 'SCTier',
  SellHouse: 'SellHouse',
  SignupUrl: 'SignupUrl',
  SiteName: 'SiteName',
  SiteServer: 'SiteServer',
  SplitScenarioFilter: 'SplitScenarioFilter',
  State: 'State',
  TcpaContent: 'TcpaContent',
  ThrottleDecisionId: 'ThrottleDecisionId',
  ThrottleId: 'ThrottleId',
  Tier: 'Tier',
  TimeOnForm: 'TimeOnForm',
  TotalLoanAmount: 'TotalLoanAmount',
  TrustedFormCertUrl: 'TrustedFormCertUrl',
  TypeOfTherapy: 'TypeOfTherapy',
  UniversalLeadId: 'UniversalLeadId',
  UserAgent: 'UserAgent',
  UUID: 'UUID',
  Vertical: 'Vertical',
  WindowCount: 'WindowCount',
  ZipCode: 'ZipCode',
  ZipCode4: 'ZipCode4',
  'prepop-phone': 'PhoneNumber',
  property_listed: 'PropertyListed',
  zeta_opt_in: 'ZetaOptIn',
  activity: 'activity',
  loan_to_value: 'LoanToValue',
  home_alarm_installed: 'HasAlarmInstalled',
  home_alarm_professional_install: 'ProfessionalInstall',
  home_alarm_purchase_timeframe: 'HomeAlarmPurchaseTimeframe',
  home_alarm_service_type: 'ServiceType',
  HasSolarInterest: 'HasSolarInterest',
  Height: 'Height',
  Weight: 'Weight',
};

const cakeValuesHash = {
  CreditRepairRequested: 'CreditRepairRequested',
  PageId: '___pageid___',
  Accident: 'Accident',
  Acres: 'acres',
  CashNeededAmount: 'additional_cash_needed',
  AdditionalInfo: 'additional_info',
  Address: 'address',
  AdoptionPath: 'adoption_path',
  AdoptionType: 'adoption_type',
  CkmAffiliateId: 'affiliate_id',
  //  'AffiliateId': 'affiliate_id',
  Age: 'age',
  AgeFirstLicensed: 'AgeFirstLicensed',
  AgentFound: 'agent_found',
  AnnualHouseholdIncome: 'AnnualHouseholdIncome',
  AnnualIncome: 'AnnualIncome',
  AnnualMileage: 'AnnualMileage',
  AutoInsurance: 'auto_insurance',
  BirthDate: 'BirthDate',
  BankruptcyForeclosure: 'bk_foreclosure_repo',
  BuyerGroupCkmBuyerContractId: 'BuyerGroupContractId',
  BuyerGroupDailyCap: 'BuyerGroupDailyCap',
  BuyerGroupFilter: 'BuyerGroupFilter',
  BuyerGroupId: 'BuyerGroupId',
  BuyerGroupName: 'BuyerGroupName',
  BuyerId: 'BuyerId',
  BuyerName: 'BuyerName',
  BuyingSituation: 'BuyingSituation',
  CkmCampaignId: 'campaign_id',
  //  'CampaignId': 'campaign_id',
  CashNeeded: 'cash_needed',
  City: 'city',
  CkmSubId1: 'ckm_subid_1',
  CkmSubId2: 'ckm_subid_2',
  CkmSubId3: 'ckm_subid_3',
  CkmSubId4: 'ckm_subid_4',
  CkmSubId5: 'ckm_subid_5',
  ContactedByAgent: 'ContactedByAgent',
  CoverageType: 'CoverageType',
  CreditRating: 'credit_rating',
  CurrentLender: 'current_lender',
  CurrentLoanType: 'current_loan_type',

  CurrentLenderOriginal: 'CurrentLenderOriginal',
  CurrentlyInsured: 'CurrentlyInsured',
  DesiredAmount: 'DesiredAmount',
  DesiredCoverage: 'DesiredCoverage',
  DesiredHvac: 'DesiredHvac',
  DesireHomeownersInsurance: 'DesireHomeownersInsurance',
  DisclosureName: 'disclosure_name',
  DoesDangerActivities: 'DoesDangerActivities',
  DoesRequireSR22: 'DoesRequireSR22',
  DownPayment: 'DownPayment',
  DriverVehicleUsageId: 'DriverVehicleUsageId',
  DueDate: 'due_date',
  Education: 'Education',
  ElectricBill: 'ElectricBill',
  ElectricCompany: 'ElectricCompany',
  //  'EmailOriginal': 'email',
  Email: 'email',
  EmploymentStatus: 'employer',
  EquipmentFinance: 'EquipmentFinance',
  EstimatedPropertyValue: 'est_property_value',
  EstimatedPurchasePrice: 'est_purchase_price',
  EstimatedDebt: 'estimated_debt',
  EstimatedDownPayment: 'estimated_down_payment',
  ExpirationDate: 'ExpirationDate',
  ExternalVendorData: 'ExternalVendorData',
  ExternalVendorId: 'ExternalVendorId',
  FirstName: 'first_name',
  Gender: 'Gender',
  HasAgent: 'HasAgent',
  HasAlarmInstalled: 'HasAlarmInstalled',
  HasAutoInsuranceInterest: 'HasAutoInsuranceInterest',
  HasBankruptcy: 'HasBankruptcy',
  HasConditions: 'HasConditions',
  HasCostco: 'HasCostco',
  HasFhaLoan: 'HasFhaLoan',
  HasForeclosure: 'HasForeclosure',
  HasHighBMI: 'HasHighBMI',
  HasHome: 'HasHome',
  HasLifeEvent: 'HasLifeEvent',
  HasPMI: 'HasPMI',
  HasProofOfInsurance: 'HasProofOfInsurance',
  HasSolar: 'HasSolar',
  FoundHome: 'home_found',
  HomeFound: 'home_found',
  HomeInsurance: 'home_insurance',
  HomeOwner: 'homeowner',
  HomeUse: 'HomeUse',
  Payload: 'ileads',
  InsuranceCarrier: 'InsuranceCarrier',
  InsurancePurpose: 'InsurancePurpose',
  IsFirstTimeBuyer: 'IsFirstTimeBuyer',
  IsHomeOwner: 'IsHomeOwner',
  IsSolarZip: 'IsSolarZip',
  IsTobaccoUser: 'IsTobaccoUser',
  LastName: 'last_name',
  LicenseStatus: 'LicenseStatus',
  LoanPurpose: 'loan_purpose',
  LookingToExpandFamily: 'LookingToExpandFamily',
  MapName: 'MapName',
  MaritalStatus: 'MaritalStatus',
  MarriedSingle: 'married_single',
  MedicareEnrolled: 'MedicareEnrolled',
  MoneyUse: 'MoneyUse',
  MonthlyPayment: 'MonthlyPayment',
  MortgageAmount: 'mortgage_amount',
  MortgageInsurance: 'mortgage_insurance',
  CurrentMortgageRate: 'mortgage_rate',
  MovingTimeFrame: 'moving_date',
  NumberOfAcres: 'NumberOfAcres',
  NumberOfChildrenInHouse: 'NumberOfChildrenInHouse',
  NumberOfPeopleInHouse: 'NumberOfPeopleInHouse',
  NumberOfVehicles: 'NumberOfVehicles',
  Occupation: 'Occupation',
  CkmOfferId: 'offer_id',
  OpenAdoption: 'open_adoption',
  MilitaryService: 'opt_served_military',
  LateOnMortgageMonths: 'opt_mortgage_late_months',
  OwnOrRent: 'own_home',
  //  'OwnOrRent': 'own_rent',
  //  'OwnOrRent': 'OwnOrRent',
  OwnedLeasedOrFinanced: 'OwnedLeasedOrFinanced',
  ParentUuid: 'ParentUuid',
  PayFrequency: 'PayFrequency',
  PersonalLoan: 'personal_loan',
  PhoneSecondary: 'phone_mobile',
  PhoneNumber: 'phone_primary',
  PolicyStartDate: 'PolicyStartDate',
  ProfessionalInstall: 'ProfessionalInstall',
  Project: 'Project',
  ProjectTimeFrame: 'ProjectTimeFrame',
  ProjectType: 'project_type',
  NewPropertyCity: 'property_city',
  NewPropertyState: 'property_state',
  NewPropertyZipcode: 'property_zip',
  NewPropertyZipCode: 'property_zip',
  PropertyCity: 'PropertyCity',
  PropertyState: 'PropertyState',
  PropertyValue: 'PropertyValue',
  PropertyZip: 'PropertyZip',
  PropertyType: 'property_type',
  PurchasePrice: 'PurchasePrice',
  PurchaseYear: 'PurchaseYear',
  RateType: 'RateType',
  RefinancePurpose: 'refi_purpose',
  //  'RefinancePurpose': 'RefinancePurpose',
  ReligiousAffiliation: 'religious_affiliation',
  CkmRequestId: 'request_id',
  // : 'RequestId': 'request_i',
  Residential: 'residential',
  ReverseMortgage: 'ReverseMortgage',
  RoofingMaterial: 'RoofingMaterial',
  SplitScenarioFilter: 'Scenario',
  SellHouse: 'sell_house',
  SellingReason: 'SellingReason',
  SiteServer: 'server',
  ServiceType: 'ServiceType',
  Shade: 'Shade',
  MysqlSignupId: 'signup_id',
  SiteName: 'site_name',
  SignupUrl: 'site_url',
  SiteVertical: 'SiteVertical',
  SplitScenarioId: 'SplitScenarioId',
  SplitScenarioName: 'SplitScenarioName',
  SplitScenarioOrdinal: 'SplitScenarioOrdinal',
  State: 'state',
  SubVerticalName: 'SubVerticalName',
  TcpaType: 'tcpa',
  TcpaId: 'TcpaId',
  TestVariation: 'TestVariation',
  ThrottleDecisionId: 'throttle_id',
  Tier: 'tier',
  SCTier: 'TierLevel',
  TimeOnForm: 'time_on_form',
  TotalLoanAmount: 'total_loan_amount',
  TyPageShown: 'TyPageShown',
  TypeOfTherapy: 'type_of_therapy',
  UniversalLeadId: 'universal_leadid',
  UserAgent: 'user_agent',
  IPAddress: 'user_ip',
  UUID: 'uuid',
  ValueIncrease: 'ValueIncrease',
  VehicleMake: 'VehicleMake',
  VehicleMake2: 'VehicleMake2',
  VehicleModel: 'VehicleModel',
  VehicleModel2: 'VehicleModel2',
  VehicleParkedAt: 'VehicleParkedAt',
  VehiclePrimaryUse: 'VehiclePrimaryUse',
  VehicleYear: 'VehicleYear',
  VehicleYear2: 'VehicleYear2',
  Vertical: 'vertical',
  Vin: 'Vin',
  Violation: 'Violation',
  WindowCount: 'windows',
  WindowType: 'WindowType',
  TrustedFormCertUrl: 'xxTrustedFormCertUrl',
  YearHomeBuilt: 'YearHomeBuilt',
  ZipCode4: 'zip4',
  ZipCode: 'zipcode',
  FacebookId: 'facebook_id',
  CurrentLoanAmount: 'CurrentLoanAmount',
  EmailOriginal: 'EmailOriginal',
  OptInDate: 'OptInDate',
  TcpaContent: 'TcpaContent',
  ThrottleId: 'ThrottleId',
  'prepop-phone': 'PhoneNumber',
  PropertyListed: 'property_listed',
  ZetaOptIn: 'zeta_opt_in',
  activity: 'activity',
  LoanToValue: 'loan_to_value',
  HomeAlarmInstalled: 'home_alarm_installed',
  HomeAlarmProfessionalInstall: 'home_alarm_professional_install',
  HomeAlarmPurchaseTimeframe: 'home_alarm_purchase_timeframe',
  HomeAlarmServiceType: 'home_alarm_service_type',
  HasSolarInterest: 'HasSolarInterest',
  AdditonalCashNeeded: 'additional_cash_needed',
  AdvertiserName: 'AdvertiserName',
  //  'AffiliateId': 'affiliate_id',
  //  'AffiliateId': 'AffiliateId',
  BatchId: 'BatchId',
  BridgePageName: 'BridgePageName',
  CallData: 'CallData',
  CampaignId: 'campaignId',
  CampaignName: 'CampaignName',
  CheckPreviousNumberOfDate: 'CheckPreviousNumberOfDate',
  ChildOfferId: 'ChildOfferId',
  Client: 'Client',
  Code: 'code',
  CompanyName: 'CompanyName',
  Destination: 'Destination',
  DestinationNumber: 'DestinationNumber',
  DistributionType: 'DistributionType',
  Employer: 'employer',
  Error: 'Error',
  EstPropertyValue: 'est_property_value',
  EstPurchasePrice: 'est_purchase_price',
  ExclusiveLead: 'exclusiveLead',
  ExternalSystem: 'ExternalSystem',
  FePayload: 'FePayload',
  Filters: 'Filters',
  FingerprintHash: 'FingerprintHash',
  //  'FingerprintHash': 'talon',
  InfutorUuid: 'InfutorUuid',
  MaxNumberRows: 'MaxNumberRows',
  Message: 'Message',
  MessageType: 'MessageType',
  MessageVertical: 'MessageVertical',
  MinSignupId: 'MinSignupId',
  MortgageRate: 'mortgage_rate',
  MySqlSignupId: 'MySqlSignupId',
  OfferId: 'offer_id',
  Override: 'Override',
  Phone: 'phone',
  Prefix: 'Prefix',
  Provider: 'Provider',
  ProviderRvmId: 'ProviderRvmId',
  RequestId: 'RequestId',
  SCBC: 'scbc',
  SendStatus: 'SendStatus',
  SignupId: 'SignupId',
  SignupPayload: 'SignupPayload',
  SIH: 'SIH',
  SinceDate: 'SinceDate',
  Source: 'source',
  Status: 'Status',
  Target: 'target',
  TestResults: 'TestResults',
  TransactionDate: 'TransactionDate',
  TriggeredSendKey: 'triggered_send_key',
  URL: 'url',
  UserIp: 'UserIp',
  Height: 'Height',
  Weight: 'Weight',
};

// https://stackoverflow.com/questions/4068573/convert-string-to-pascal-case-aka-uppercamelcase-in-javascript
const toPascalCase = string => {
  return `${string}`
    .replace(new RegExp(/[-_]+/, 'g'), ' ')
    .replace(new RegExp(/[^\w\s]/, 'g'), '')
    .replace(
      new RegExp(/\s+(.)(\w+)/, 'g'),
      ($1, $2, $3) => `${$2.toUpperCase() + $3.toLowerCase()}`
    )
    .replace(new RegExp(/\s/, 'g'), '')
    .replace(new RegExp(/\w/), s => s.toUpperCase());
};

const dataToPascal = (values, type = 'finalSubmit') => {
  const useHash = type === 'CheckRules' ? rulesEnginePascalHash : pascalHash;

  const pascalData = {};
  Object.keys(values).forEach(el => {
    const propertyExists = Object.prototype.hasOwnProperty.call(useHash, el);
    if (propertyExists) {
      const prop = useHash[el];
      pascalData[prop] = values[el];
    } else {
      // const pascalProp = toPascalCase(el);
      // pascalData[pascalProp] = values[el];
      pascalData[el] = values[el];
    }
  });

  // I'm not sure a better place to accomplish this, but I don't like doing this check in this function.
  // TODO -- find a better place to do this sitevertical conversion.
  const differentVerticals = ['hs_roofing', 'hs_window'];
  if (differentVerticals.includes(pascalData.SiteVertical)) {
    pascalData.OwnHome = pascalData.OwnOrRent;
    delete pascalData.OwnOrRent;
  }

  return pascalData;
};

// transition psacal values to the original cake values, if needed for prepop
const pascalToCakeValue = values => {
  const cakeValues = {};
  Object.keys(values).forEach(el => {
    const propertyExists = Object.prototype.hasOwnProperty.call(
      cakeValuesHash,
      el
    );
    if (propertyExists) {
      const prop = cakeValuesHash[el];
      if (el === 'OwnOrRent') {
        cakeValues.own_rent = values[el];
        cakeValues.own_home = values[el];
      } else {
        cakeValues[prop] = values[el];
      }
    } else {
      cakeValues[el] = values[el];
    }
  });

  return cakeValues;
};

const toSnakeCase = (values, config) => {
  const snakeValues = {};
  Object.keys(values).forEach(el => {
    const replace = el.replace(/([A-Z])/g, '_$1').toLowerCase();

    let result = replace;

    if (keepCase.includes(el)) {
      result = el;
    } else if (replace[0] === '_') {
      if (replace === '_zip_code') {
        result = 'zipcode';
      } else if (replace === '_phone_number') {
        result = 'phone_primary';
      } else {
        result = replace.substring(1);
      }
    }
    let value = values[el];

    if (typeof values[el] === 'boolean') {
      if (!values[el]) {
        value = '0';
      } else {
        value = '1';
      }
    }

    if (typeof values[el] === 'number' && el !== 'EstimatedDownPayment') {
      value = values[el].toString();
    }

    if (el === 'BirthDate') {
      const [year, month, day] = value.split('-');

      snakeValues.year = year;
      snakeValues.BirthYear = year;
      snakeValues.month = month;
      snakeValues.BirthMonth = month;
      snakeValues.day = day;
      snakeValues.BirthDay = day;
    }

    if (el === 'PropertyType' || el.includes('PropertyType')) {
      if (
        config?.surveyName === 'qualifiedsolarsurvey.com' &&
        value === 'Mobile Home'
      ) {
        snakeValues.property_type = 'Manufactured';
      } else {
        snakeValues.property_type = value;
      }
    }
    if (result === 'current_mortgage_rate') {
      snakeValues.mortgage_rate = value;
    }
    if (result === 'estimated_property_value') {
      snakeValues.est_property_value = value;
    }
    if (result === 'current_loan_amount') {
      snakeValues.mortgage_amount = value;
    }
    if (result === 'cash_needed_amount') {
      snakeValues.additional_cash_needed = value?.toString();
    }

    if (el.includes('HomeService')) {
      const [homeService, fieldName] = el.split('HomeService');
      const updatedName = fieldName.replace(/"|_/g, '');

      if (updatedName === 'ProjectType') {
        snakeValues.ProjectType = value;
        snakeValues.project_type = value;
      } else if (updatedName === 'PropertyType') {
        snakeValues.PropertyType = value;
        snakeValues.property_type = value;
      } else if (updatedName === 'WindowCount') {
        snakeValues.Windows = value;
      } else {
        snakeValues[updatedName] = value;
      }
    }
    if (el === 'InsurancePropertyType') {
      snakeValues.PropertyType = value;
    }
    if (el === 'EmploymentStatus') {
      snakeValues.employer = value;
    }
    if (el === 'MovingTimeFrame') {
      snakeValues.moving_date = value;
    }
    if (el === 'EstimatedPurchasePrice') {
      snakeValues.est_purchase_price = value;
    }
    if (el === 'FoundHome') {
      snakeValues.home_found = value;
    }
    if (el === 'NewPropertyZipcode') {
      snakeValues.property_zip = value;
    }
    if (el === 'OwnOrRent') {
      snakeValues.own_rent = value;
      snakeValues.own_home = value;
    }
    if (el === 'MilitaryService') {
      snakeValues.opt_served_military = value;
    }
    if (el === 'HasSolarInterest') {
      snakeValues.SolarInterest = value;
    }
    if (el === 'HasAlarmInstalled') {
      snakeValues.home_alarm_installed = value === '1' ? 'Yes' : 'No';
    }
    if (el === 'ServiceType') {
      snakeValues.home_alarm_service_type = value;
    }
    if (el === 'HomeAlarmPurchaseTimeFrame') {
      snakeValues.home_alarm_purchase_timeframe = value;
    }
    if (el === 'ProfessionalInstall') {
      snakeValues.home_alarm_professional_install = value;
    }
    if (el === 'Height') {
      const height = value;
      const feet = Math.floor(height / 12);
      const inches = height - feet * 12;
      snakeValues.Feet = feet;
      snakeValues.Inches = inches;
      snakeValues.FeetButton = `${feet}ft`;
      snakeValues.InchesButton = inches;
    }

    snakeValues[result] = value;
  });

  return snakeValues;
};

export { dataToPascal, pascalToCakeValue, toSnakeCase };
